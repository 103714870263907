import { FormHelperText, InputLabel, Typography, InputLabelProps, BoxProps } from '@mui/material';
import { Editor, EditorState, DefaultDraftBlockRenderMap, EditorProps } from 'draft-js';
import 'draft-js/dist/Draft.css';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ToolPanel from 'src/components/TextEditor/components/ToolPanel';
import Box from 'src/components/Box';
import TypographyNoWrap from 'src/components/TypographyNoWrap';
import clsx from 'src/utils/clsx';
import { BlockType, InlineStyle } from 'src/enums/TextEditor';
import { removeExtraSpacesFromString } from 'src/utils/string';

import styles from './styles';

type TextEditorProps = {
  label?: string;
  labelSx?: InputLabelProps['sx'];
  value: EditorState;
  onChange?: ((editorState: EditorState) => void) | null;
  // event type inherited from 'draft-js'
  // eslint-disable-next-line @typescript-eslint/ban-types
  onBlur?: (e: SyntheticEvent<{}>) => void;
  error?: unknown;
  readOnly?: boolean;
  placeholder?: string;
  disableVerticalGutters?: boolean;
  usePredefinedMinHeight?: boolean;
  isCounterVisible?: boolean;
  maxValue?: number;
  truncateReadOnlyValue?: boolean;
  autoFocus?: boolean;
  wrapperSx?: BoxProps['sx'];
};

const TextEditor: React.FC<TextEditorProps> = props => {
  const {
    truncateReadOnlyValue,
    label,
    value,
    onChange = null,
    error,
    readOnly,
    placeholder,
    onBlur,
    disableVerticalGutters,
    usePredefinedMinHeight,
    isCounterVisible,
    maxValue,
    autoFocus,
    labelSx = {},
    wrapperSx,
  } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const editorRef = useRef(null);

  useEffect(() => {
    if (autoFocus && editorRef.current) {
      editorRef.current.focus();
      setIsFocused(true);
    }
  }, []);

  const hasError = Boolean(error);

  const wrapperStyles = clsx(styles.wrapper, [
    [styles.hasError, hasError],
    [styles.hasFocus, isFocused],
    [styles.readyOnlyStyles, readOnly],
    [styles.disableVerticalGutters, disableVerticalGutters],
    [styles.usePredefinedMinHeight, usePredefinedMinHeight],
    [styles.truncateReadOnlyStyles, truncateReadOnlyValue && readOnly],
    [wrapperSx, Boolean(wrapperSx)],
  ]);

  const currentBlockType = useMemo(() => {
    const selection = value.getSelection();
    const content = value.getCurrentContent();
    const block = content.getBlockForKey(selection.getStartKey());

    return block.getType() as BlockType;
  }, [value]);

  const hasInlineStyle = useCallback(
    (inlineStyle: InlineStyle) => {
      const currentStyle = value.getCurrentInlineStyle();

      return currentStyle.has(inlineStyle);
    },
    [value],
  );

  const onBlurEditor: EditorProps['onBlur'] = useCallback(
    e => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocused(false);
    },
    [onBlur],
  );

  const handleFormatPastedText = (text: string, html: string) => {
    const updatedPastedText = {
      html: removeExtraSpacesFromString(html),
      text: removeExtraSpacesFromString(text),
    };

    return updatedPastedText;
  };

  const editor = (
    <Editor
      ref={editorRef}
      placeholder={placeholder}
      editorState={value}
      onChange={onChange}
      readOnly={readOnly}
      onFocus={() => setIsFocused(true)}
      onBlur={onBlurEditor}
      blockRenderMap={DefaultDraftBlockRenderMap}
      formatPastedText={handleFormatPastedText}
      spellCheck
    />
  );

  return (
    <Box sx={wrapperStyles}>
      {label && (
        <InputLabel error={hasError} sx={labelSx}>
          {label}
        </InputLabel>
      )}
      {!readOnly && (
        <ToolPanel
          editorState={value}
          onChange={onChange}
          currentBlockType={currentBlockType}
          hasInlineStyle={hasInlineStyle}
        />
      )}
      {truncateReadOnlyValue && readOnly ? (
        <TypographyNoWrap className="typographyNoWrap" component="div" lineClamp={2}>
          {editor}
        </TypographyNoWrap>
      ) : (
        editor
      )}
      {error && <FormHelperText error={hasError}>{error}</FormHelperText>}
      {isCounterVisible && (
        <Typography sx={clsx(styles.counter, [[styles.absolute, hasError]])} variant="caption">
          {`${value.getCurrentContent().getPlainText().length} / ${maxValue}`}
        </Typography>
      )}
    </Box>
  );
};

export default TextEditor;
